exports.prescription_Items = [
    //1-11 and 19 prefilled
    //Font Style Change 
    "ALL MEDICINES THAT HAVE SINGLE DAILY DOSE WITHOUT TIME WRITTEN SHOULD BE GIVEN EARLY MORNING EMPTY STOMACH MIXED WITH ALL OTHER MORNING MEDICINES AS A MORNING HEALTHDRINK",
    "SYR BIQPLUS  10 ML A DAY FOR 1 MONTH",
    "SYR L CARNOSHINE 400 10 ML A DAY FOR 1 MONTH ",
    "SYR TENONTEN 10 ML 2 TIMES A DAY FOR 1 MONTH ",
    "TAB SHARPZ/AlphaGPC 1 TAB A DAY FOR 1 MONTH ",
    "SYR DOFOTIL/GutClear 10 ML------10 ML FOR 1 MONTH ",
    "ARISTOZYME DROPS  1ML DAILY FOR 1 MONTH ",
    "TAB KRAVIONQ10 1 TAB  A  DAY FOR 1 MONTH ",
    "TAB RISDONE/RPDONE 1MG TAB GIVE 1/8---------0-------1/4 8:00 PMFOR 1 MONTH ", // bold
    "TAB TOMKID 10 MG 1/4 AT 8 AM---------------1/4 AT 4PM FOR 1 MONTH ",   //bold
    "SYR Flunil 1ML--------1ML FOR 1 MONTH ",
    "TAB CENTIQ P 1 TAB DAILY MORNING FOR 1 MONTH",
    "SYR KREBSOMAG 10ML A DAY FOR 1 MONTH ",
    "SYR QCARNI10 10 ML A DAY FOR 1 MONTH ",
    "SYR BRIVAZEN/ BRIVASURE 1ML--------1ML FOR 1 MONTH ",
    "TAB Flunil/Fludac 10mg-------- 2 TIMES A DAY ",
    "TAB BRIV 100 MG 1/4---------1/4 8:00 PM FOR 1 MONTH ",
    "SYR  OXETOL/ZENOXA 2 ML  DAILY MORNING FOR 1 MONTH",
    "SYR MEMTONE 2ML------------2ML 8:00 PM  FOR 1 MONTH ",
    "TAB DEPRANEX 5mg 1/4-------------- FOR 21 DAYS ",
    "TAB REJUNEXCD3 1 TAB A DAY FOR 1 MONTH ",
    "TAB Allegra 120  1/2 Tab 2 times FOR 1 MONTH ",
    "SYR ASP Carno 10 ML A DAY FOR 1 MONTH ",
    "SYR STORAX 1ML A DAY FOR 1 MONTH ",
    "SYR CERCETAM 1ML A DAY FOR 1 MONTH ",
    "SYR KLCLAV DS 10 ML A DAY FOR 15 DAYS ",
    "TAB Bycine OD 1 TAB DAILY -- FOR 1 MONTH",
    "TAB VERNACE 500mg  --- 1 TAB 2 TIMES A DAY - FOR 1 Month",
    "TAB  STORAX P R 1 TAB DAILY MORNING FOR 1 MONTH",
    "CAP OZ M3 1 CAP A DAY FOR 1 MONTH ",
    "NO/SATURDAY/WEEKEND HOLIDAY TO ANY MEDICINES EXCEPT BOLD ONES"
] ;



/*
 General Medicines
 SYR FLEXON 5ML____5ML____5ML FOR 5 DAYS
 SYR KLCLAV DS 5ML_________5ML FOR 5 DAYS
 SYR CHESTON LS 5ML_____5ML_____5ML FOR 5 DAYS
 SYR MOTAIRLC KID 5ML_________5ML FOR 21 DAYS
 SYR SINAREST 5ML_________5ML FOR 5 DAYS
 SYR OFM 5ML_________5ML FOR 5 DAYS
 VISYLAC GG DROP 1ML DAILY FOR 5 DAYS
 SYR RINICLANSI 5ML_______5ML FOR 10 DAYS
 SYR ZIFI (100) 2.5ML________2.5ML FOR 7 DAYS
 SYR DEFCORT 5ML__________5ML FOR 5 DAYS
*/

/*
  EPILEPSY MEDICINES
  SYR ZENOXXA 2ML__________2ML FOR 3 MONTHS
  SYR BRIVASURE/BRIVAZEN 2ML__________2ML FOR 3 MONTHS
  SYR LACOTIDE 2ML_________2ML FOR 3 MONTHS
  SYR VALPROF 2ML__________2ML FOR 3 MONTHS
  T. BRIV (100) 1/4_________1/4 FOR MONTHS
  SYR LEVERA 2ML__________2ML FOR 3 MONTHS
  T. PERAMPA 2MG___________2MG  FOR 3 MONTHS
*/

  /*
   CONTROL_MEDICINES
   T. TOMKID 10MG 1/2_________1/2  FOR 1 MONTH
   T.RPDONE 1MG   1/4_________1/4 FOR 1 MONTH
   T. RISDONE LS (2MG) 1/2________1/2 FOR 1 MONTH
   SYR MEMTONE 2ML__________2ML_________5ML FOR 1 MONTH 
   */

   /*
   COGNITIVE MEDICINES
   SYR  L-CARNOSTONE 10ML DAILY FOR 1 MONTH
   SYR BIGPLUS 10ML DAILY FOR 1 MONTH
   SYR PARADOX 10ML DAILY FOR 1 MONTH
   SYR ASPCARNO 10ML DAILY FOR 1 MONTH
   SYR KRTSWUE 10ML DAILY FOR 1 MONTH
   SYR MECOB 10ML DAILY FOR 1 MONTH
   */

   /*
   FINE MOTOR MEDICINES
   SYR KRETSOMAG 10ML DAILY FOR 1 MONTH
   SYR QCARWIO 10ML DAILY FOR 1 MONTH
   T. KRANONQ10 1 TAB DAILY FOR  1MONTH
   T.MINAMINQPLUS 1 TAB DAILY FOR 1 MONTH
   T.BYCINE OD 1 TAB DAILY FOR 1 MONTH
   T.REJUNEX CDS 1 TAB DAILY FOR 1 MONTH
   */
  
   /*
    CROSS MOTOR MEDICINES
    T. CENTIQ P 1 TAB DAILY FOR 1 MONTH
    T. PIRACETUM 800MG 1 TAB DAILY FOR 1 MONTH
    SYR CERCETAM 1 ML DAILY FOR 1 MONTH
  */

    /*
    CONSTIPATION CONTROLLER
    SYR DOTOTIL 10ML __________10ML FOR 1 MONTH
    SYR REGCLEAR/MNOUT/LAXOTIL  1OZ(30ML) DAILY FOR 1 MONTH
    SYR GUTCLEAR 10ML DAILY FOR 1 MONTH
    */

//Checkboxes 
/**
 * 
 * Current Issues
 *  1.Jumping/Running/Climbing/Roaming
 *  2.Hitting/Hurting/Biting/Throwing
 *  3.Stimming/Object Obsession
 *  4.Indigestion/Constipation
 *  5.Pointing/Imitation/Non-Verbal Communication
 *  6.Vocabulary/Speech/Comprehension/Composition
 *  7.Sleep
 *   
 * 
 */






/*
1.ARISTOZYME DROPS  1ML DAILY FOR 1 MONTH
2.SYR PEGCLEAR 10 ML------10 ML FOR 1 MONTH
3. SYR BIQPLUS /COGNI3 10 ML A DAY FOR 1 MONTH
4. SYR L CARNOSHINE 400/KRTSHINE 400 10 ML A DAY FOR 1 MONTH
5.SYR TENONTEN 10 ML A DAY FOR 1 MONTH
6.SYR STORAX 1ML A DAY FOR 1 MONTH
7.SYR CERCETAM 1ML A DAY FOR 1 MONTH
8.SYR MEMTONE 2ML------------2ML FOR 1 MONTH
9.SYR KREBSOMAG 5ML A DAY FOR 1 MONTH
10.SYR QCARNI10 10 ML A DAY FOR 1 MONTH
11. TAB RISDONE/RPDONE 1MG TAB GIVE 1/8---------0-------1/4 FOR 1 MONTH
12. TAB KRAVIONQ10 1 TAB  A  DAY FOR 1 MONTH
13. TAB SHARPZ 1 TAB A DAY FOR 1 MONTH
14.T MONTECORT L 1/2--------------1/2   FOR 21 DAYS
15. TAB TOMKID 10 MG 1/4 AT 8 AM---------------1/4 AT 4PM FOR 1 MONTH
16. SYR KLCLAV DS 10 ML A DAY FOR 15 DAYS
17. TAB REJUNEXCD3 1 TAB A DAY FOR 1 MONTH
18. CAP OZ M3 1 CAP A DAY FOR 1 MONTH
19. TAB BRIV 100 MG 1/4---------1/4 FOR 1 MONTH
20. SYR BRIVAZEN/ BRIVASURE 1ML--------1ML FOR 1 MONTH
21. BLANK

*/